import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContratoNominaDTO } from '@src/app/models/Nomina/Contrato.model';
import { ControllerApiList } from '@src/app/models/SaludPlus/Controller/ControllerList';
import { FiltersSearch } from '@src/app/models/SaludPlus/FilterObject';
import { SaludPlusApiResponse } from '@src/app/models/base/SaludplusApiResponse';
import { RequestResultServiceService } from '@src/app/services/Generals/request-result-service.service';
import { Observable, lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContratosNominaService {
  static instance: ContratosNominaService;
  error: HttpErrorResponse;
  constructor(
    private http: HttpClient,
    private resolveRequestResultService: RequestResultServiceService,) {
    ContratosNominaService.instance = this;
  }

  /**
   * Guardar registro
   * @param Datos datos a guardar
   */
  Guardar<T>(Datos: ContratoNominaDTO) {
    return this.http.post<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}`,
      Datos
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
  }
  async GuardarAsync<T>(Datos: ContratoNominaDTO) {
    let req = this.http.post<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}`,
      Datos
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    let dataMap = await lastValueFrom(req);
    return dataMap;
  }
  /**
   * Actualizar
   * @param Datos datos a actualizar
   * @param id id a actualizar
   */
  ActualizarRegistro<T>(Datos: ContratoNominaDTO, id: number) {
    Datos.id = id;
    const record = this.http.put<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}`,
      Datos
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    return record;
  }

  /**
   * Buscar cargo por su id
   * @param id Identificador del registro
   * @returns
   */
  BuscarRegistro<T>(id: number): Observable<T> {
    let params = new HttpParams().set("idContrato", id);
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}`,
      {
        params: params,
      }
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    return record;



  }
  /**
   * Eliminar registro por su id
   * @param id Identificador del registro
   * @returns
   */
  Delete<T>(id: number): Observable<T> {
    let params = new HttpParams().set("idContrato", id);
    const record = this.http.delete<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}`,
      {
        params: params,
      }
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    return record;
  }


  /**
  * Obtener Cargos
  * @returns Observable<SaludPlusApiResponse<T>>
  */
  ObtenerCargos<T>(): Observable<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/Cargos`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    return record;
  }
  async ObtenerCargosAsync<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/Cargos`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerTiposContratacion<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/TiposContratacion`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerPeriodosPagos<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/PeriodosPagos`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerEstadoContrato<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/EstadoContrato`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerCajaCompensacion<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/CajaCompensacion`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerPromotoraSalud<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.General.General}/Entidades`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerAdministradoraPensiones<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/AdministradoraPensiones`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerFondoCesantia<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/FondoCesantia`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerArl<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/Arl`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }


  async ObtenerTipoRiesgos<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/TipoRiesgos`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }


  async ObtenerMetodosPago<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/MetodosPago`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerTiposCuenta<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/TiposCuenta`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }

  async ObtenerBancos<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/Bancos`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }


  async ObtenerTipoSalario<T>(): Promise<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/TiposSalario`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }


  async CargarEmpleados<T>(search: string): Promise<T> {
    var prop = "segundoApellido,primerApellido,segundoNombre,primerNombre,numeroDocumento";


    const fromObject =
    {
      "pageSize": 50,
      "pageNumber": 1,
      "properties": prop,
      "filter": search,
      "sort": "id",
    }

    let params = new HttpParams({ fromObject: fromObject });
    let request = this.http.get<SaludPlusApiResponse<T>>(`${ControllerApiList.Nomina.Empleados}/Listado`, {
      params: params,
    }).pipe(map((s) => s.result));;

    let dataMap = await lastValueFrom(request);
    return dataMap;
  }



  async ValidarReglasAsync<T>(id: number): Promise<T> {
    let params = new HttpParams({ fromObject: { idContrato: id } });
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Contrato}/ConceptosNomina`, {params:params}
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    let dataMap = await lastValueFrom(record);
    return dataMap;
  }



}
