import { Component, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationEnd } from '@angular/router';
import { ProcessType, SPlusDialogCloseResult, SPlusDialogSettings, SPlusFormsDialogsService, SPlusMoreOption, SPlusOption, SPlusProcessMessageComponent, SplusTabsFormComponent } from '@saludplus/forms';
import { ContratoConfiguracionConceptosNominaDTO, ContratoNominaDTO } from '@src/app/models/Nomina/Contrato.model';
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ContratosSettings } from '../config/contratos.settings';
import { HttpErrorResponse } from '@angular/common/http';
import { CargosDTO } from '@src/app/models/Nomina/Cargos';
import { ContratosNominaService } from '../services/contratos.service';
import { FiltersData } from '@src/app/models/base/filterModel';
import { Empleados } from '@src/app/models/Nomina/Empleados.model';
import differenceInDays from 'date-fns/differenceInDays';
import { EmpleadosSearch } from '../../empleados/config/empleados.search';
import { IConfiguracionConceptos } from '@src/app/models/Nomina/ConfiguracionConceptos.model';
import { TercerosDTO } from '@src/app/models/General/TercerosDTO';
import { CellClickEvent, GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.scss']
})
export class ContratoNominaComponent extends SplusBaseForm implements OnInit, OnDestroy {

  @ViewChild('tabsComponent', { static: false }) tabsComponent: SplusTabsFormComponent;
  @Input() override id = 0;
  @Input() override loadSource;
  @Input() override processType: ProcessType = ProcessType.create;

  datosContrato: ContratoNominaDTO;
  nombreCompleto = "";
  cargos: CargosDTO[] = [];
  empleados: Empleados[] = [];
  datosEmpleado: Empleados;
  // Forms - control de los estados
  maxDate = new Date();
  minFinalDate: Date;
  maxInicialDate: Date;
  ListadoEmpleado = EmpleadosSearch

  @ViewChild("container", { read: ViewContainerRef }) public containerRef: ViewContainerRef;
  closePop: boolean = false;

  public dataConceptosAplicados: ContratoConfiguracionConceptosNominaDTO[] = [];
  public dataConceptosDisponibles: ContratoConfiguracionConceptosNominaDTO[] = [];

  ValidateOpened: boolean = true;
  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/nomina/contratos/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearContrato",
      showIn: ProcessType.view,
      text: "Crear nuevo contrato",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarContrato",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },
  ];
  filtersEmpleado: FiltersData[] = [];

  prueba = 1;
  searchingDocument = false;
  private unsubcribe$ = new Subject<void>();
  loadingLiquidar = false;
  private contratosSettings = inject(ContratosSettings);
  private contratosService = inject(ContratosNominaService);
  dataSelectedAplicados: ContratoConfiguracionConceptosNominaDTO[] = [];
  dataSelectedDisponibles: ContratoConfiguracionConceptosNominaDTO[] = [];
  indexSelectionRowAplicados: number[] = [];
  indexSelectionRowDisponibles: number[] = [];
  editedRowIndex: number = 0;
  private renderer = inject(Renderer2);
  private docClickSubscription: Subscription = new Subscription();
  constructor() {

    super()
    this.filtersEmpleado = [
      {
        propertyName: "selectionPopup",
        propertyValue: true,
      }
    ];


    this.routingState.loadRouting();


  }
  onSubmit(event: any): void {

    this.ValidateOpened = true;

    event.preventDefault();
    this.submitted = true;
    if (this.FormRecord.valid) {
      if (this.FormRecord.pristine || this.searchingDocument) return;
      this.isLoading = true;
      if (this.processType === this.ProcessType.edit) {
        this.UpdateRecord();
      } else {
        this.SaveRecord();
      }
    } else {
      this.isLoading = false;
    }
  }
  async SaveRecordInPopup() {
    let data = {} as ContratoNominaDTO;
    data = this.FormRecord.value;
    data.idEmpleado = this.FormRecord.get("idEmpleado").value?.id;
    let response = await this.contratosService
      .GuardarAsync<number>(data);
    if (response) {
      this.id = response
    }
  }
  onTerceroSelectionChange(event: number | TercerosDTO, dataItem: any) {
    if (event === undefined) {
      dataItem.idTercero = null;
      dataItem.tercero = null;
    }

    else if (typeof event === 'number') {
      dataItem.idTercero = event;
    } else {
      dataItem.idTercero = event.id;
      dataItem.tercero = event;
      if(!this.formGroup) this.formGroup = dataItem;
      this.formGroup.get("tercero").setValue(event);
    }
    //this.gridForm.get('idTercero').setValue(typeof event === 'number' ? event : event.id);
    // Realiza cualquier otra lógica necesaria
  }

  SaveRecord(): void {

    let data = {} as ContratoNominaDTO;
    data = this.FormRecord.value;
    data.idEmpleado = this.FormRecord.get("idEmpleado").value?.id;

    this.contratosService
      .Guardar<number>(data)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.id = res;
            if (this.loadSource != 'tab' && this.loadSource != 'popup') {
              this.router.navigate([`${res}`], {
                queryParams: { mode: "i" },
                relativeTo: this.route,
              });
            }

          }

        }
      });
  }


  UpdateRecord(): void {
    this.isLoading = true;
    this.submitted = true;
    let data = {} as ContratoNominaDTO;
    data = this.FormRecord.value;
    data.idEmpleado = this.FormRecord.get("idEmpleado").value?.id;
    this.dataConceptosAplicados.forEach(element => {
      element.idTercero = element.tercero?.id;
    });
    if (this.dataConceptosAplicados.some(s => !s.idTercero)) {
      let dialogSettings = {} as SPlusDialogSettings;
      dialogSettings.title = "Error edición";
      dialogSettings.isHtml = true;
      dialogSettings.description =
        "Todos los conceptos deben tener un tercero seleccionado";
      dialogSettings.actions = [{ text: "Cerrar" }];
      this.splusFormsDialogsService.Show(dialogSettings);
      return;

    }
    data.contratoConfiguracionConceptosNomina = this.dataConceptosAplicados;
    data.contratoConfiguracionConceptosNomina.forEach(element => {
      element.idContrato = Number(this.id);
    });

    this.contratosService
      .ActualizarRegistro<boolean>(data, this.id)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([], {
              queryParams: { mode: "u" },
              queryParamsHandling: "merge",
            });
          }


        },
        error: (err) => {
          if (err instanceof HttpErrorResponse && err.status == 401) return;
          console.warn("Error critico de lado del cliente", err);
          let dialogSettings = {} as SPlusDialogSettings;
          dialogSettings.title = "Actualizar contrato";
          dialogSettings.description =
            "Hubo un error al intentar actualizar el contrato por favor intentelo más tarde";
          dialogSettings.actions = [{ text: "Cerrar" }];
          this.splusFormsDialogsService.Show(dialogSettings);
          this.isLoading = false;
          this.submitted = false;
        },
      });
  }


  DeleteRecord(): void {
    this.isDeleting = true;
    this.isLoading = true;
    this.contratosService.Delete<boolean>(this.id).subscribe({
      next: (res) => {
        if (res) {
          this.isDeleting = false;
          this.router.navigate(["../"], {
            queryParams: { mode: "d" },
            queryParamsHandling: "merge",
            relativeTo: this.route,
          });
        }

      }
    });
  }


  LoadRecord(): void {
    this.isLoading = true;
    this.contratosService.BuscarRegistro<ContratoNominaDTO>(this.id).subscribe({
      next: (res) => {
        this.isDeleting = false;
        if (res) {
          this.datosContrato = res;
          this.empleados = [];
          this.empleados.push(res.empleado);
          res.fechaInicio = this.intl.parseDate(res.fechaInicio.toString(), ["G", "d"]);
          if (res.fechaFin) {
            res.fechaFin = this.intl.parseDate(res.fechaFin.toString(), ["G", "d"]);
          }

          if (this.datosContrato) {
            this.FormRecord.patchValue(this.datosContrato);
            this.FormRecord.get("idEmpleado").setValue(this.datosContrato.empleado);

            this.TerminoContratoChange(this.FormRecord.get('idTerminoContrato').value);
            this.MetodoPagoChange(this.FormRecord.get('idMetodoPago').value);


            this.isLoading = false;
          }
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar contrato";
        dialogSettings.description =
          "Hubo un error al intentar cargar el contrato por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
      },
    });
  }

  goEdit(): void {
    this.routingState.goEdit();
  }

  cancelEdit(): void {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.splusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }
  /**
   * Cancelar edicion
   */
  cancelUpdate() {
    this.FormRecord.patchValue(this.datosContrato);
    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });
  }
  clearForm(): void {
    this.FormRecord.reset();
    this.FormRecord.updateValueAndValidity();
    this.submitted = false;
  }

  TerminoContratoChange(selectedValue: any) {

    if (selectedValue == 1) {

      this.FormRecord.get('fechaFin').enable();
      this.FormRecord.get('fechaFin').setValidators(Validators.required);
      this.FormRecord.get('fechaFin').updateValueAndValidity();
      this.FormRecord.get('fechaFin')['required'] = true;

    } else if (selectedValue == 2) {
      this.FormRecord.get('fechaFin').reset();
      this.FormRecord.get('fechaFin').updateValueAndValidity();
      this.FormRecord.get('fechaFin').disable();
      this.FormRecord.get('fechaFin')['required'] = false;

    } else {
      this.FormRecord.get('fechaFin').enable();
      this.FormRecord.get('fechaFin').reset();
      this.FormRecord.get('fechaFin').clearValidators();
      this.FormRecord.get('fechaFin').updateValueAndValidity();
      this.FormRecord.get('fechaFin')['required'] = false;
    }
  }

  MetodoPagoChange(selectedValue: any) {
    if (selectedValue == 1) {

      this.FormRecord.get('idTipoCuenta').reset();
      this.FormRecord.get('idTipoCuenta').updateValueAndValidity();
      this.FormRecord.get('idTipoCuenta').disable();
      this.FormRecord.get('idTipoCuenta')['required'] = false;

      this.FormRecord.get('idBanco').reset();
      this.FormRecord.get('idBanco').updateValueAndValidity();
      this.FormRecord.get('idBanco').disable();
      this.FormRecord.get('idBanco')['required'] = false;

      this.FormRecord.get('numeroCuenta').reset();
      this.FormRecord.get('numeroCuenta').updateValueAndValidity();
      this.FormRecord.get('numeroCuenta').disable();
      this.FormRecord.get('numeroCuenta')['required'] = false;


    } else {

      this.FormRecord.get('idTipoCuenta').enable();
      this.FormRecord.get('idTipoCuenta').setValidators(Validators.required);
      this.FormRecord.get('idTipoCuenta').updateValueAndValidity();
      this.FormRecord.get('idTipoCuenta')['required'] = true;

      this.FormRecord.get('idBanco').enable();
      this.FormRecord.get('idBanco').setValidators(Validators.required);
      this.FormRecord.get('idBanco').updateValueAndValidity();
      this.FormRecord.get('idBanco')['required'] = true;

      this.FormRecord.get('numeroCuenta').enable();
      this.FormRecord.get('numeroCuenta').setValidators(Validators.required);
      this.FormRecord.get('numeroCuenta').updateValueAndValidity();
      this.FormRecord.get('numeroCuenta')['required'] = true;
    }
  }


  ngOnInit(): void {


    this.icon = this.route.snapshot.data['icon'];
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.loadFormComponents();

    if (this.loadSource == 'popup') {
      this.options = [];
      this.FormRecord.get("idEmpleado").setValue(this.datosEmpleado);
    } else if (this.loadSource == 'tab') {
      this.options = [];
      this.controlProcess();
    }
    else {
      this.id = this.route.snapshot.params['id'];
      this.controlProcess();

      this.router.events.pipe(takeUntil(this.unsubcribe$)).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.controlProcess();
        }
      });


    }
    this.ObtenerCargos();
    this.ValidatFechas();
    this.After();

  }
  After() {
    this.FormRecord.enable();
    if (this.loadSource == 'popup') {
      this.FormRecord.get("idEmpleado").disable();
    }
    if (this.id) {
      this.LoadRecord();
    }

    this.isLoading = false;

  }

  /**
  * Cargar datos departamentos
  */
  ObtenerCargos() {

    this.contratosService.ObtenerCargos<CargosDTO[]>().subscribe({
      next: (res) => {
        if (res) {
          this.cargos = res;
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        this.FormRecord.enable();
        console.warn(err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Error cargar listado";
        dialogSettings.description =
          "Error de comunicacion con el server por favor intentelo mas tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
      },
    });
  }
  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f(): any {
    return this.FormRecord.controls;
  }
  /**
   * Componentes del formulario
   */
  loadFormComponents() {
    this.FormRecord = this.contratosSettings.FormComponents();
  }
  ngOnDestroy() {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }

  /**
   * Determina el estado de la pantalla (insercion, vista, edicion, eliminacion)
   */
  controlProcess() {
    const params = this.util.decodeURLParams(this.router.url);
    const mode = params.mode;

    if (this.route.routeConfig.path === ":id") {
      if (mode === "e") {
        this.processType = ProcessType.edit;
      } else {
        this.processType = ProcessType.view;
      }
      this.showMessageProcess = mode === "i" || mode === "u";

      this.messageProcess.title = "Confirmación";
      this.messageProcess.message = "Contrato guardado exitosamente";
      this.messageProcess.type = "success";
      if (mode === "df") {
        this.processType = ProcessType.view;
        this.messageProcess.title = "Error";
        this.messageProcess.message =
          "No se puede eliminar el contrato porque tiene información asociada";
        this.messageProcess.type = "error";
        this.showMessageProcess = true;
      }
    } else {
      if (mode === "d") {
        this.processType = ProcessType.create;
        this.messageProcess.title = "Confirmación";
        this.messageProcess.message = "Contrato Eliminado exitosamente";
        this.messageProcess.type = "success";

        this.showMessageProcess = true;
      }

      if (mode === "df") {
        this.processType = ProcessType.edit;
        this.messageProcess.title = "Error";
        this.messageProcess.message =
          "No se puede eliminar el contrato porque tiene información asociada";
        this.messageProcess.type = "error";
        this.showMessageProcess = true;
      }
    }
  }

  /**
   * Nuevo registro
   */
  override newRecord() {
    this.routingState.newRecord(this.route);
  }
  /**
   * Abre alerta para eliminar registro
   */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar Contrato";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar este contrato?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }

  OpenDiagBuscarEMpleados(template: TemplateRef<unknown>): void {
    this.closePop = false;
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.content = template;
    dialogSettings.appendTo = this.containerRef;
    dialogSettings.width = "70%";
    dialogSettings.title = "Listado Empleados";
    dialogSettings.actions = [{ text: "Cerrar" }]
    dialogSettings.actionsLayout = "end"
    this.splusFormsDialogsService.ShowCustom(dialogSettings)
  }
  GetSelectionEmpleado(data: any) {
    let datos = {
      id: data.id,
      numeroDocumento: data.numeroDocumento,
      nombreCompleto: data.nombre

    } as Empleados;
    this.empleados = [];
    this.empleados.push(datos);
    this.FormRecord.get("idEmpleado").setValue(datos);
    this.closePop = true;

  }
  async Filtrar(search) {
    this.empleados = [];
    let emp = await this.contratosService.CargarEmpleados<any[]>(search);

    this.empleados = emp.map(s => {
      let datos = {
        id: s.id,
        numeroDocumento: s.numeroDocumento,
        nombreCompleto: s.nombre
      } as Empleados;
      return datos;

    });

  }
  CargarSalario(data: CargosDTO) {
    const salario = this.FormRecord.get("salario").value;
    if (!salario || salario == 0) {
      this.FormRecord.get("salario").setValue(data.salario)
    }
  }
  ValidatFechas() {
    this.FormRecord.controls["fechaInicio"].valueChanges
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((s) => {
        let fechaFinal = this.FormRecord.controls["fechaFin"].value;
        this.minFinalDate = s;
        if (fechaFinal) {
          let days = differenceInDays(fechaFinal, s);
          if (days < 0) {
            this.FormRecord.controls["fechaInicio"].setErrors({
              notValid: true,
            });
          }
        }
      });
    this.FormRecord.controls["fechaFin"].valueChanges
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe(async (s) => {
        let fechaInicial = this.FormRecord.controls["fechaInicio"].value;
        this.maxInicialDate = s;
        if (fechaInicial) {
          let days = differenceInDays(s, fechaInicial);
          if (days < 0) {
            this.FormRecord.controls["fechaFin"].setErrors({
              notValid: true,
            });
          }
        }
      });
  }

  ngAfterViewInit(): void {
    this.docClickSubscription.add(
      this.renderer.listen("document", "click", this.onDocumentClick.bind(this))
    );
    //this.FormRecord.value
    if (this.id)
      this.ValidarReglas();


  }
  selecctionChageConceptosAplicados(dataSelect: number[]) {
    let selectData = [];
    dataSelect.forEach((element) => {
      const data = this.dataConceptosAplicados.find(s => s.idConfiguracionConceptosNomina == element);
      if (data)
        selectData.push(data);
    });
    this.dataSelectedAplicados = selectData;
    //console.log(this.dataSelected);
  }
  selecctionChageConceptosDisponibles(dataSelect: number[]) {
    let selectData = [];
    dataSelect.forEach((element) => {
      const data = this.dataConceptosDisponibles.find(s => s.idConfiguracionConceptosNomina == element);
      if (data)
        selectData.push(data);
    });
    this.dataSelectedDisponibles = selectData;
    //console.log(this.dataSelected);
  }
  async ValidarReglas() {

    // data.idEmpleado = this.FormRecord.get("idEmpleado").value?.id;

    let response = await this.contratosService.ValidarReglasAsync<ContratoConfiguracionConceptosNominaDTO[]>(this.id);

    this.dataConceptosAplicados = response.filter(x => x.id);
    this.dataConceptosDisponibles = response.filter(x => !x.id);




  }
  public ValidateClose(status: string): void {
    this.ValidateOpened = false;
  }
  agregarConceptos() {
    this.tabsComponent.selectTab(0);
    this.dataConceptosDisponibles = this.dataConceptosDisponibles.filter(s => !this.dataSelectedDisponibles.includes(s));
    this.dataConceptosAplicados = [...this.dataConceptosAplicados, ...this.dataSelectedDisponibles];
    this.dataConceptosAplicados.sort((a, b) => a.idConfiguracionConceptosNomina - b.idConfiguracionConceptosNomina);
    this.dataSelectedDisponibles = [];
    this.dataSelectedAplicados = [];
    this.indexSelectionRowAplicados = [];
    this.indexSelectionRowDisponibles = [];
    this.tabsComponent.selectTab(1);
    this.FormRecord.markAsDirty();
    this.FormRecord.updateValueAndValidity();
  }
  public cellClickHandler(args: CellClickEvent): void {
    if (args.isEdited) return;
    if (this.processType == ProcessType.view) return;
    if (this.formGroup && this.formGroup.valid) this.saveCurrent();
    if (!this.gridRef) return;


    if (this.formGroup && !this.formGroup.valid && args.isEdited) {
      return;
    }

    if (!args.isEdited) {
      this.closeEditor();
      this.createFormGroup(args.dataItem);
      this.gridRef.editCell(args.rowIndex, args.columnIndex, this.formGroup);
      this.editedRowIndex = args.rowIndex;

    }
  }
  public createFormGroup(dataItem: any) {
    this.formGroup = new FormGroup({
      tercero: new FormControl(dataItem.tercero ? dataItem.tercero : null, Validators.required)
    });
  }
  @ViewChild("gridPpal") public gridRef: GridComponent;
  private onDocumentClick(e: Event): void {

    if (!this.gridRef) return;
    if (
      this.formGroup &&
      this.formGroup.valid &&
      !this.matches(e.target, "#gridPpal tbody *, #gridPpal .k-table-td *, .info")
    ) {
      this.saveCurrent();
    }
  }
  public saveCurrent(): void {
    this.FormRecord.markAsDirty();
    this.FormRecord.updateValueAndValidity();
    const data = this.dataConceptosAplicados[this.editedRowIndex];
    if (!data && !this.formGroup.get("tercero")?.value) return;

    Object.assign(data, this.formGroup.getRawValue());


    this.closeEditor();
  }
  private closeEditor(
    rowIndex: number = this.editedRowIndex
  ): void {
    if (!this.gridRef) return;

    if (this.gridRef) this.gridRef.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }


  matches(el, selector) {
    return (el.matches || el.msMatchesSelector).call(el, selector);
  }

  formGroup: FormGroup;

  dialogEliminarConceptosAplicadosSelecionados() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Remover Conceptos Seleccionados";
    dialogSettings.appendTo = this.containerRef;
    dialogSettings.description =
      "¿Estás seguro que desea remover estos conceptos  del empleado?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Sí, Remover", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
        } else {
          if (result.value) {
            this.dataConceptosAplicados = this.dataConceptosAplicados.filter(s => !this.dataSelectedAplicados.includes(s));
            this.dataConceptosDisponibles = [...this.dataConceptosDisponibles, ...this.dataSelectedAplicados];
            this.closePop = true;
            this.dataSelectedDisponibles = [];
            this.dataSelectedAplicados = [];
            this.indexSelectionRowAplicados = [];
            this.indexSelectionRowDisponibles = [];
            this.FormRecord.markAsDirty();
            this.FormRecord.updateValueAndValidity();
            setTimeout(() => {
              this.closePop = false;
            }, 500)
          }
        }
      });
  }

}



